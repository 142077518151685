var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BCard', {
    attrs: {
      "header-class": "py-1"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "font-weight-bolder text-uppercase"
        }, [_vm._v(" " + _vm._s(_vm.$t('packageConfig.agencyPackageConfigsList')) + " ")])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "d-flex flex-md-row flex-column align-items-stretch gap-1"
  }, [_c('BRow', {
    staticClass: "mb-50 flex-1"
  }, [!_vm.isRoleF2 ? _c('BCol', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "w-100 mb-0 mt-25 mt-lg-0",
    attrs: {
      "label-for": "agency-code-filter",
      "label-class": "h6",
      "label": _vm.$t('packageConfig.columns.agency')
    }
  }, [_c('v-select', {
    staticClass: "w-100",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "id": "agency-code-filter",
      "options": _vm.agencyOptions,
      "label": "agencyCode",
      "clearable": "",
      "loading": _vm.loadingAgencies,
      "placeholder": _vm.$t('packageConfig.placeholder.agency'),
      "reduce": function reduce(val) {
        return val.agencyCode;
      }
    },
    on: {
      "open": _vm.handleOpenAgency,
      "search": _vm.handleSearchAgency
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(_ref) {
        var agencyName = _ref.agencyName,
          agencyCode = _ref.agencyCode;
        return [_c('div', {
          staticStyle: {
            "width": "100%"
          }
        }, [_c('span', {
          staticClass: "d-block font-weight-bold text-truncate"
        }, [_vm._v(" " + _vm._s(agencyCode) + " "), _c('small', [_vm._v("(" + _vm._s(agencyName) + ")")])])])];
      }
    }, {
      key: "option",
      fn: function fn(_ref2) {
        var agencyName = _ref2.agencyName,
          agencyCode = _ref2.agencyCode;
        return [_c('div', {
          staticStyle: {
            "width": "100%"
          }
        }, [_c('span', {
          staticClass: "d-block font-weight-bold text-truncate"
        }, [_vm._v(" " + _vm._s(agencyCode) + " "), _c('small', [_vm._v("(" + _vm._s(agencyName) + ")")])])])];
      }
    }, {
      key: "spinner",
      fn: function fn(_ref3) {
        var loading = _ref3.loading;
        return [loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _vm._e()];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm.loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")])];
      },
      proxy: true
    }], null, false, 3656176114),
    model: {
      value: _vm.agencyCodeFilter,
      callback: function callback($$v) {
        _vm.agencyCodeFilter = $$v;
      },
      expression: "agencyCodeFilter"
    }
  })], 1)], 1) : _vm._e(), _c('BCol', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "w-100 mb-0 mt-25 mt-lg-0",
    attrs: {
      "label-for": "package-config-filter",
      "label-class": "h6",
      "label": _vm.$t('packageConfig.columns.packageConfig')
    }
  }, [_c('v-select', {
    staticClass: "w-100",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "id": "package-config-filter",
      "options": _vm.packageConfigOptions,
      "label": "name",
      "clearable": "",
      "loading": _vm.loadingPackageConfigs,
      "placeholder": _vm.$t('packageConfig.columns.packageConfig'),
      "reduce": function reduce(val) {
        return val.id;
      }
    },
    on: {
      "open": _vm.handleOpenPackageConfig,
      "search": _vm.handleSearchPackageConfig
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(_ref4) {
        var name = _ref4.name,
          price = _ref4.price;
        return [_c('div', {
          staticStyle: {
            "width": "100%"
          }
        }, [_c('span', {
          staticClass: "d-block font-weight-bolder text-truncate"
        }, [_vm._v(" " + _vm._s(name) + " "), _c('small', [_vm._v("(" + _vm._s(_vm.formatCurrency(price)) + ")")])])])];
      }
    }, {
      key: "option",
      fn: function fn(_ref5) {
        var name = _ref5.name,
          price = _ref5.price;
        return [_c('div', {
          staticStyle: {
            "width": "100%"
          }
        }, [_c('span', {
          staticClass: "d-block font-weight-bolder text-truncate"
        }, [_vm._v(" " + _vm._s(name) + " "), _c('small', [_vm._v("(" + _vm._s(_vm.formatCurrency(price)) + ")")])])])];
      }
    }, {
      key: "spinner",
      fn: function fn(_ref6) {
        var loading = _ref6.loading;
        return [loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _vm._e()];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm.loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.packageConfigIdFilter,
      callback: function callback($$v) {
        _vm.packageConfigIdFilter = $$v;
      },
      expression: "packageConfigIdFilter"
    }
  })], 1)], 1), _c('BCol', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "w-100 mb-0 mt-25 mt-lg-0",
    attrs: {
      "label-for": "is-expired-filter",
      "label-class": "h6",
      "label": _vm.$t('packageConfig.columns.isExpired')
    }
  }, [_c('v-select', {
    staticClass: "w-100",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "id": "is-expired-filter",
      "options": _vm.isExpiredOptions,
      "label": "label",
      "clearable": "",
      "placeholder": _vm.$t('packageConfig.columns.isExpired'),
      "reduce": function reduce(val) {
        return val.value;
      }
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(_ref7) {
        var label = _ref7.label;
        return [_vm._v(" " + _vm._s(_vm.$t("packageConfig.".concat(label))) + " ")];
      }
    }, {
      key: "option",
      fn: function fn(_ref8) {
        var label = _ref8.label;
        return [_vm._v(" " + _vm._s(_vm.$t("packageConfig.".concat(label))) + " ")];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm.loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.isExpiredFilter,
      callback: function callback($$v) {
        _vm.isExpiredFilter = $$v;
      },
      expression: "isExpiredFilter"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex-center align-items-end pb-50"
  }, [_c('BButton', {
    staticClass: "p-1",
    attrs: {
      "variant": "flat-danger"
    },
    on: {
      "click": _vm.clearFilter
    }
  }, [_vm._v(" Xoá bộ lọc ")])], 1)], 1), _c('BTable', {
    ref: "refTable",
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "calc(100vh - 400px)",
      "height": "calc(100vh - 400px)"
    },
    attrs: {
      "sticky-header": true,
      "items": _vm.resultArray,
      "responsive": "",
      "striped": true,
      "fields": _vm.tableColumnsRole,
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": "",
      "busy": _vm.loading,
      "hover": true,
      "borderless": true
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center text-dark my-2 gap-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('loading')))])], 1)];
      },
      proxy: true
    }, _vm._l(_vm.tableColumnsRole, function (column) {
      return {
        key: "head(".concat(column, ")"),
        fn: function fn() {
          return [_c('span', {
            key: column,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("packageConfig.columns.".concat(column))) + " ")])];
        },
        proxy: true
      };
    }), {
      key: "cell(agency)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('div', [_c('div', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" " + _vm._s(item.agency.agencyCode) + " ")]), _c('small', {
          staticClass: "font-italic"
        }, [_vm._v("(" + _vm._s(item.agency.agencyName) + ")")])])];
      }
    }, {
      key: "cell(parentAgency)",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('div', [_c('div', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" " + _vm._s(item.agency.parentAgency.agencyCode) + " ")]), _c('small', {
          staticClass: "font-italic"
        }, [_vm._v("(" + _vm._s(item.agency.parentAgency.agencyName) + ")")])])];
      }
    }, {
      key: "cell(packageConfig)",
      fn: function fn(row) {
        return [_c('div', {
          staticClass: "d-flex-center justify-content-start gap-2"
        }, [_vm._v(" " + _vm._s(row.item.packageConfig.name) + " "), _c('BButton', {
          attrs: {
            "variant": "flat-primary",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.handleToggleRowDetail(row, false);
            }
          }
        }, [_vm._v(" Chi tiết gói ")])], 1)];
      }
    }, {
      key: "cell(monthsUse)",
      fn: function fn(row) {
        return [_c('div', {
          staticClass: "d-flex-center justify-content-start gap-2"
        }, [_vm._v(" " + _vm._s(row.item.monthsUse) + " ")])];
      }
    }, {
      key: "cell(expiredAt)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.expiredAt).date) + " "), item.expiredAt ? _c('div', {
          class: _vm.getExpiredCount(item.expiredAt)[1]
        }, [_vm._v(" " + _vm._s(_vm.getExpiredCount(item.expiredAt)[0]) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(enable)",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('IAmSwitch', {
          attrs: {
            "checked": item.enable,
            "name": "check-button",
            "confirm": true,
            "custom-class": "mr-0 mt-50 custom-control-success",
            "disabled": false,
            "re-update": false
          },
          on: {
            "update:checked": function updateChecked($event) {
              return _vm.$set(item, "enable", $event);
            },
            "input": function input($event) {
              return _vm.changeActive(item);
            }
          }
        })];
      }
    }, {
      key: "cell(action)",
      fn: function fn(row) {
        return [_c('div', {
          staticClass: "d-flex gap-2"
        }, [_vm.isRoleF2 ? _c('BButton', {
          attrs: {
            "variant": "warning",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.increaseButtonHandle(row.item);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('packageConfig.agencyPackageConfigsIncrease')) + " ")]) : _vm._e(), _c('BButton', {
          attrs: {
            "variant": "outline-info",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.handleToggleRowDetail(row, true);
            }
          }
        }, [_vm._v(" Chi tiết sử dụng gói ")]), _c('BButton', {
          attrs: {
            "variant": "info",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.handleEditDomain(row.item);
            }
          }
        }, [_vm._v(" Cài đặt domain ")])], 1)];
      }
    }, {
      key: "row-details",
      fn: function fn(_ref13) {
        var _ref13$item = _ref13.item,
          packageConfig = _ref13$item.packageConfig,
          packagePeriod = _ref13$item.packagePeriod,
          apiKey = _ref13$item.apiKey,
          userApiKey = _ref13$item.userApiKey;
        return [_vm.isShowDetailPeriod ? _c('div', {
          staticClass: "d-flex gap-3"
        }, [packagePeriod ? _c('PackageDetail', {
          attrs: {
            "options-period": packagePeriod,
            "limit-list": {
              searchLimit: packageConfig.searchLimit,
              bookingLimit: packageConfig.bookingLimit,
              issueLimit: packageConfig.issueLimit
            }
          }
        }) : _vm._e(), apiKey ? _c('ApiKeyDetail', {
          attrs: {
            "title": "API KEY",
            "api-key": apiKey
          }
        }) : _vm._e(), userApiKey ? _c('ApiKeyDetail', {
          attrs: {
            "title": "USER API KEY",
            "api-key": userApiKey
          }
        }) : _vm._e()], 1) : _c('PackageDetail', {
          attrs: {
            "options": packageConfig.descriptions
          }
        })];
      }
    }], null, true)
  }), _c('b-row', {
    staticClass: " d-flex-center justify-content-md-between"
  }, [_c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center mb-50 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('paginationText.showing')) + " ")]), _c('v-select', {
    staticClass: "per-page-selector d-inline-block mx-50",
    attrs: {
      "options": _vm.sizePerPageLgOptions,
      "clearable": false,
      "append-to-body": true,
      "calculate-position": _vm.withPopper
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sizePerPage,
      callback: function callback($$v) {
        _vm.sizePerPage = $$v;
      },
      expression: "sizePerPage"
    }
  }), _c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('paginationText.from')) + " " + _vm._s(_vm.fromItem) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.toItem) + " " + _vm._s(_vm.$t('paginationText.outOf')) + " " + _vm._s(_vm.totalItems) + " ")])], 1), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0 mt-25 mt-sm-0",
    attrs: {
      "total-rows": _vm.totalItems,
      "per-page": _vm.sizePerPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1), _c('ModalBuy', {
    attrs: {
      "buy-item-props": _vm.increaseItem,
      "agency-item": _vm.agencyItem,
      "is-increase": true
    },
    on: {
      "refetchData": _vm.fetchAgencyPackageConfigs
    }
  }), _c('ModalDomainConfig', {
    attrs: {
      "id": _vm.configItem.id,
      "domain-list": _vm.configItem.domains
    },
    on: {
      "refetchData": _vm.fetchAgencyPackageConfigs
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }